import React, { useEffect } from 'react';
import { Routes, Route } from "react-router-dom";
import { isMobileOnly, isTablet, useMobileOrientation  } from 'react-device-detect';
import TagManager from 'react-gtm-module'

import DesktopPreRegister from './layout/desktop/DesktopPreRegister';
import TermOfServiceLayout from './layout/desktop/TermOfServiceLayout';
import PrivacyPolicyLayout from './layout/desktop/PrivacyPolicyLayout';
import DataDeletionLayout from './layout/desktop/DataDeletionLayout';
import TermOfServiceLayoutApple from './layout/desktop/TermOfServiceLayoutApple';
import PrivacyPolicyLayoutApple from './layout/desktop/PrivacyPolicyLayoutApple';
import DataDeletionLayoutApple from './layout/desktop/DataDeletionLayoutApple';
import MobilePreRegister from './layout/mobile/MobilePreRegister';
import TermOfServiceLayoutMobile from './layout/mobile/TermOfServiceLayoutMobile';
import PrivacyPolicyLayoutMobile from './layout/mobile/PrivacyPolicyLayoutMobile';
import DataDeletionLayoutMobile from './layout/mobile/DataDeletionLayoutMobile';
import TermOfServiceLayoutMobileApple from './layout/mobile/TermOfServiceLayoutMobileApple';
import PrivacyPolicyLayoutMobileApple from './layout/mobile/PrivacyPolicyLayoutMobileApple';
import DataDeletionLayoutMobileApple from './layout/mobile/DataDeletionLayoutMobileApple';
import Index from './layout/Index';
import WarningLayout from './layout/WarningLayout';
import NoMatchLayout from './layout/NoMatchLayout';
import EventDetailLayout from './layout/mobile/EventDetailLayout';

import '@fontsource/noto-sans-thai/500.css';
import '@fontsource/noto-sans-thai/700.css';
import '@fontsource/noto-sans-thai/900.css';
import '@fontsource/noto-sans-thai';

import './App.module.scss';

const tagManagerArgs = {
    gtmId: 'GTM-M39M7ZCG'
}
 
TagManager.initialize(tagManagerArgs);

function App() {

// --- CDN

    const cdn = 'https://sko-cdn.zoltangames.com/web/';

// --- Get url referrer
    const { referrer } = document;
    const adjust = referrer === 'adjust.com' ? true : false

// --- Orientation and Mount

  const { isLandscape } = useMobileOrientation()
  const isLandscapeVerified = isLandscape && adjust !== false

  useEffect(() => {
    import('react-facebook-pixel')
    .then((x) => x.default)
    .then((ReactPixel) => {
      ReactPixel.init('360000256384232')
      ReactPixel.pageView()
    })
  }, []);

  const mobileRoutes = () => {
    return (
      <Routes>
        <Route path="/" element={isLandscapeVerified ? <WarningLayout cdn={cdn} /> : <MobilePreRegister cdn={cdn} isMobile={isMobileOnly} />} />
        <Route index element={isLandscapeVerified ? <WarningLayout cdn={cdn} /> : <MobilePreRegister cdn={cdn} isMobile={isMobileOnly} />} />
        {/* <Route cpath="pre-register" element={isLandscapeVerified ? <WarningLayout cdn={cdn} /> : <MobilePreRegister cdn={cdn} isMobile={isMobileOnly} />} /> */}
        <Route path="event-detail-2" element={<EventDetailLayout cdn={cdn} isMobile={isMobileOnly} pageNumber={2}/>} />
        <Route path="event-detail-4" element={<EventDetailLayout cdn={cdn} isMobile={isMobileOnly} pageNumber={4}/>} />
        <Route path="term-of-service" element={<TermOfServiceLayoutMobileApple cdn={cdn} />} />
        <Route path="privacy-policy" element={<PrivacyPolicyLayoutMobileApple cdn={cdn} />} />
        <Route path="privacy-policy-2" element={<PrivacyPolicyLayoutMobileApple cdn={cdn} />} />
        <Route path="data-deletion-instruction" element={<DataDeletionLayoutMobileApple cdn={cdn} />} />
        <Route path="term-of-service-ios" element={<TermOfServiceLayoutMobileApple cdn={cdn} />} />
        <Route path="privacy-policy-ios" element={<PrivacyPolicyLayoutMobileApple cdn={cdn} />} />
        <Route path="data-deletion-instruction-ios" element={<DataDeletionLayoutMobileApple cdn={cdn} />} />
        <Route path="*" element={<NoMatchLayout />} />
      </Routes>
    )
  }

  const desktopRoutes = () => {
    return (
      <Routes>
        <Route path="/" element={<DesktopPreRegister cdn={cdn}/>} />
        <Route index element={<DesktopPreRegister cdn={cdn}/>} />
        {/* <Route path="pre-register" element={<DesktopPreRegister cdn={cdn}/>} /> */}
        <Route path="term-of-service" element={<TermOfServiceLayoutApple cdn={cdn} />} />
        <Route path="privacy-policy" element={<PrivacyPolicyLayoutApple cdn={cdn} />} />
        <Route path="privacy-policy-2" element={<PrivacyPolicyLayoutApple cdn={cdn} />} />
        <Route path="data-deletion-instruction" element={<DataDeletionLayoutApple cdn={cdn} />} />
        <Route path="term-of-service-ios" element={<TermOfServiceLayoutApple cdn={cdn} />} />
        <Route path="privacy-policy-ios" element={<PrivacyPolicyLayoutApple cdn={cdn} />} />
        <Route path="data-deletion-instruction-ios" element={<DataDeletionLayoutApple cdn={cdn} />} />
        <Route path="*" element={<NoMatchLayout />} />
      </Routes>
    )
  }

  return (
    <>
      {isMobileOnly ? ( 
          mobileRoutes()
        ) : (
          isTablet ? (
            mobileRoutes()
          ) : (
            desktopRoutes()
          )  
        )
      }
    </>
  )
}

export default App;
